
import Vue from 'vue'
import { pagingBuilder, sortingBuilder } from 'kwik-vue/utils/tables'
import { ActiveFilterItem, IHeaderOption, IPagingInput, ISortingInput, KwikPageToolbar, KwikTableAction, KwikTooltipButtonIcon, KwikFullContainer, KwikDialog } from 'kwik-vue'
import { IProductionOrdersGetInput } from '../types'
import { dispatchCancelProductionOrder, dispatchGetProductionOrderForTimeline, dispatchGetProductionOrdersCurrentPage } from '../store/actions'
import { readProductionOrdersCount, readProductionOrdersCurrentPage } from '../store/getters'
import { RouteNames } from '@/router/names'
import FiltersBar from '@/components/filtersBar/FiltersBar.vue'
import { clockToFormatDate, clockToDate } from '@/utils/clockManager'
import { FilterOption } from '@/components/filtersBar/FilterOptions'
import { FilterTypes } from '@/components/filtersBar/FilterTypes'

import TimelineComponent from '@/components/timeline/TimelineComponent.vue'

export default Vue.extend({
  name: 'FFProductionOrders',
  components: {
    KwikFullContainer,
    KwikPageToolbar,
    KwikTableAction,
    KwikTooltipButtonIcon,
    KwikDialog,
    FiltersBar,
    TimelineComponent
  },
  data () {
    return {
      options: {} as any,
      loading: true,
      events: [] as any[],
      schedulerHeight: '400px',
      filters: [] as ActiveFilterItem[],
      showFilters: true,
      showTimeline: false,
      showProductionNotes: false,
      productionNotes: '',
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Ordini di produzioni', disabled: false }
      ]
    }
  },
  computed: {
    headers () : IHeaderOption[] {
      const defaultHeaders = [
        {
          text: 'ID',
          sortable: true,
          value: 'id',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Data di avvio',
          sortable: true,
          value: 'start_clock',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Data di fine',
          sortable: true,
          value: 'end_clock',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Stato',
          sortable: true,
          value: 'status',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Materiale',
          sortable: true,
          value: 'material.name',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Macchina',
          sortable: true,
          value: 'machine.name',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Lotto prodotto',
          sortable: true,
          value: 'produced_batch',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Lotto prodotto',
          sortable: true,
          value: 'produced_fronzen_batch',
          hidden: false,
          align: 'start'
        },
        {
          text: this.$t('commons.buttons.actions'),
          value: 'actions',
          sortable: false,
          hidden: false
        }
      ] as IHeaderOption[]
      return defaultHeaders
    },
    productionOrdersItems () {
      return readProductionOrdersCurrentPage(this.$store)
    },
    total () {
      return readProductionOrdersCount(this.$store)
    },
    filterOptions (): FilterOption[] {
      return [
        {
          key: 'status',
          label: 'Stato',
          field: 'status',
          type: FilterTypes.PRODUCTION_TRANSACTION_STATE
        },
        {
          key: 'material_id',
          label: 'Materiale',
          field: 'material_id',
          type: FilterTypes.MATERIAL
        },
        {
          key: 'machine_id',
          label: 'Macchina',
          field: 'machine_id',
          type: FilterTypes.MACHINE
        }
      ] as FilterOption[]
    }
  },
  methods: {
    async loadPage () {
      this.loading = true
      const paging: IPagingInput = pagingBuilder(this.options)
      const sorting: ISortingInput = sortingBuilder(this.options)
      let status = null
      let materialId = null
      let machineId = null
      if (this.filters) {
        this.filters.forEach((filter) => {
          switch (filter.field) {
            case 'status':
              status = filter.value
              break
            case 'material_id':
              materialId = filter.value
              break
            case 'machine_id':
              machineId = filter.value
              break
            default:
            // nothing
          }
        })
      }
      const payload: IProductionOrdersGetInput = {
        paging: paging,
        sorting: sorting,
        status: status,
        materialId: materialId,
        machineId: machineId
      }
      await dispatchGetProductionOrdersCurrentPage(this.$store, payload)
      this.loading = false

      this.loadTimelineData()
    },
    async openDetail (id: string) {
      await this.$router.push(
        {
          name: RouteNames.FF_PRODUCTION_ORDERS_ROWS,
          params: { production_orders_id: id }
        }
      )
    },
    async schedule () {
      await this.$router.push(
        {
          name: RouteNames.FF_CREATE_ODP
        }
      )
    },
    clockToFormatDate (clock: number) {
      return clockToFormatDate(clock, 'shortDateTime')
    },
    toggleFilter () {
      this.showFilters = !this.showFilters
    },
    filterData (activeFilters: ActiveFilterItem[]) {
      this.filters = activeFilters
    },
    async cancel (productionOrderId: number) {
      await dispatchCancelProductionOrder(this.$store, productionOrderId)
      await this.loadPage()
    },
    toggleTimeline () {
      this.showTimeline = !this.showTimeline
      if (this.showTimeline) {
        this.loadTimelineData()
      }
    },
    async loadTimelineData () {
      const productionOrders = await dispatchGetProductionOrderForTimeline(this.$store)
      const items = [] as any[]
      productionOrders.forEach((e: any) => {
        if (e.start_clock !== e.end_clock) {
          const startTime = clockToDate(e.start_clock)
          const endTime = clockToDate(e.end_clock)
          const description = `[ ${e.id} ] - ${e.material.name} #Lot: ${e.produced_batch_id}`
          items.push(
            {
              productionOrderId: e.id,
              start_date: startTime,
              end_date: endTime,
              text: description,
              section_id: e.machine.id
            }
          )
        }
      })
      this.events = items
    },
    showNotes (notes: string) {
      this.productionNotes = notes
      this.showProductionNotes = true
    }
  },
  watch: {
    options: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    },
    filters: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    }
  }
})
