
import Vue from 'vue'
import { ActiveFilterItem, IHeaderOption, IPagingInput, ISortingInput, KwikTableAction } from 'kwik-vue'
import { INotificationGetInput } from '../types'
import { readNotificationsCount, readNotificationsCurrentPage } from '../store/getters'
import { pagingBuilder, sortingBuilder } from 'kwik-vue/utils'
import { dispatchArchiveNotification, dispatchGetNotificationsCurrentPage } from '../store/actions'
import { clockToFormatDate } from '@/utils/clockManager'
import { FilterOption } from '@/components/filtersBar/FilterOptions'
import { FilterTypes } from '@/components/filtersBar/FilterTypes'
import FiltersBar from '@/components/filtersBar/FiltersBar.vue'

export default Vue.extend({
  name: 'FFTrackingNotesTable',
  components: { FiltersBar, KwikTableAction },
  data () {
    return {
      options: {} as any,
      loading: true,
      filters: [] as ActiveFilterItem[],
      showFilters: true,
      archived: false
    }
  },
  computed: {
    headers () : IHeaderOption[] {
      const defaultHeaders = [
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: 30
        },
        {
          text: 'ID',
          sortable: false,
          value: 'id',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Data',
          sortable: false,
          value: 'clock',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Circolare',
          sortable: false,
          value: 'message',
          hidden: false,
          align: 'start'
        }
      ] as IHeaderOption[]
      return defaultHeaders
    },
    notificationsItems () {
      return readNotificationsCurrentPage(this.$store)
    },
    total () {
      return readNotificationsCount(this.$store)
    },
    filterOptions (): FilterOption[] {
      return [
        {
          key: 'message',
          label: 'Circolare',
          field: 'message',
          type: FilterTypes.STRING
        }
      ] as FilterOption[]
    }
  },
  methods: {
    async loadPage () {
      this.loading = true
      const paging: IPagingInput = pagingBuilder(this.options)
      const sorting: ISortingInput = sortingBuilder(this.options)
      let message = null
      if (this.filters) {
        this.filters.forEach((filter) => {
          switch (filter.field) {
            case 'message':
              message = filter.value
              break
            default:
            // nothing
          }
        })
      }
      const payload: INotificationGetInput = {
        paging: paging,
        sorting: sorting,
        message: message,
        archived: this.archived
      }
      await dispatchGetNotificationsCurrentPage(this.$store, payload)
      this.loading = false
    },
    clockToFormatDate (clock: number) {
      return clockToFormatDate(clock, 'shortDateTime')
    },
    toggleFilter () {
      this.showFilters = !this.showFilters
    },
    filterData (activeFilters: ActiveFilterItem[]) {
      this.filters = activeFilters
    },
    async archive (id: number) {
      await dispatchArchiveNotification(this.$store, id).then(() => {
        this.loadPage()
      })
    },
    async toggleArchived () {
      this.archived = !this.archived
      await this.loadPage()
    }
  },
  watch: {
    options: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    },
    filters: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    }
  }
})
