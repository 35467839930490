
import Vue, { PropType } from 'vue'
import { ILookupBase } from '../filtersBar/lookupType'

export default Vue.extend({
  name: 'ProductionStateLookupSelector',
  props: {
    initSelected: {
      type: Object as PropType<ILookupBase>
    },
    editingEnabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    label: {
      type: String,
      default () {
        return 'Stato'
      }
    },
    placeholder: {
      type: String,
      default () {
        return 'Seleziona lo stato'
      }
    },
    mode: {
      type: String,
      default () {
        return 'all'
      }
    },
    returnLabel: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      loadingError: false,
      items: [
        { text: 'INSERITO', value: 'INSERTED' },
        { text: 'FALLITO', value: 'FAILED' },
        { text: 'IN CORSO', value: 'DOING' },
        { text: 'COMPLETATO', value: 'COMPLETED' },
        { text: 'ANNULLATO', value: 'CANCELED' }
      ] as ILookupBase[],
      selectedItem: null as ILookupBase | null
    }
  },
  computed: {
    selectedCustomerFullName (): string {
      return ('ok')
    }
  },
  methods: {
    formatItem (item: ILookupBase): string {
      return this.$t('production.' + item.value) as string
    }
  },
  watch: {
    selectedItem: {
      handler (val) {
        if (val) {
          if (!this.returnLabel) this.$emit('selection', val.id)
          else this.$emit('selection', this.selectedItem)
        }
      }
    },
    initSelected: {
      handler (val) {
        this.selectedItem = val
      }
    }
  },
  async mounted () {
    if (this.initSelected) {
      this.selectedItem = this.initSelected
    }
  }
})
